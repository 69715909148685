import React from "react"
import "./collapsable.css"

export default function CollapsableItem({ heading, body }) {
  return (
    <div class="container is-narrow is-padded">
      <details class="details">
        <summary class="h2">{heading}</summary>
        <div
          class="content mt-3"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </details>
    </div>
  )
}
