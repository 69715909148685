import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Text from "../components/blocks/text"
import ImageGallery from "../components/blocks/imagegallery"
import CollapsableContent from "../components/blocks/collapsable"
import SEO from "../components/seo"

const AboutPage = ({ data }) => {
  const { heading, subheading, blocks } = data.datoCmsAboutPage

  return (
    <Layout fillMenu>
      <SEO title={heading} />

      <div class="container mt-5 is-small is-text-center">
        <h1 class="heading mb-3">{heading}</h1>
        <p class="subheading">{subheading}</p>
      </div>

      {blocks.map(block => {
        if (block.__typename === "DatoCmsCollapsableContent") {
          return (
            <div class="mb-3">
              <CollapsableContent
                key={block.id}
                heading={block.heading}
                body={block.body}
              ></CollapsableContent>
            </div>
          )
        }
        if (block.__typename === "DatoCmsText") {
          return (
            <div class="mb-3">
              <Text
                key={block.id}
                heading={block.heading}
                subheading={block.subheading}
                body={block.body}
              ></Text>
            </div>
          )
        }
        if (block.__typename === "DatoCmsImageGallery") {
          return (
            <div class="mb-5">
              <ImageGallery
                key={block.id}
                images={block.images.map(image => ({
                  src: image.url,
                }))}
              />
            </div>
          )
        }
        return null
      })}
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPage {
    datoCmsAboutPage {
      heading
      subheading
      mainImage {
        url
      }
      blocks {
        ... on DatoCmsCollapsableContent {
          id
          heading
          body
          __typename
        }
        ... on DatoCmsText {
          id
          heading
          subheading
          body
          __typename
        }
      }
    }
  }
`
